// The default variants for flags are 'on' and 'off', and default variants for experiments are 'control' and 'treatment'
type DEFAULT_VARIANTS = 'control' | 'treatment' | 'on' | 'off'

// The possible variants for each feature
export type FeatureVariants = {
  '3d-tiles-improved': DEFAULT_VARIANTS
  blog: DEFAULT_VARIANTS
  'books-new-upload-ux': DEFAULT_VARIANTS
  canvas: DEFAULT_VARIANTS
  christmas: DEFAULT_VARIANTS
  collage: DEFAULT_VARIANTS
  'collage-hebrew-text': DEFAULT_VARIANTS
  'dpi-threshold-parametric': DEFAULT_VARIANTS
  'gallery-walls': DEFAULT_VARIANTS
  'holiday-badge': DEFAULT_VARIANTS
  'homepage-new-header-2':
    | 'off'
    | 'control'
    | 'treatment-post-scroll-btn'
    | 'treatment-shine-btn'
  'homepage-new-seen-on': DEFAULT_VARIANTS
  'homepage-new-title': DEFAULT_VARIANTS
  'homepage-new-products': DEFAULT_VARIANTS
  halloween: DEFAULT_VARIANTS
  jumble: DEFAULT_VARIANTS
  'material-info-icon-expand': DEFAULT_VARIANTS
  'new-cart-icon': DEFAULT_VARIANTS
  'art-collection-dec-2024': DEFAULT_VARIANTS
  'art-in-plus': DEFAULT_VARIANTS
  'photo-books': DEFAULT_VARIANTS
  'photo-books-medium-size': DEFAULT_VARIANTS
  'photo-book-matting': DEFAULT_VARIANTS
  'photo-books-photos-limit': 'off' | '150' | '300'
  pricing_us_simple_prices_jul_24:
    | 'off'
    | 'control'
    | 'treatment-decreasing-margins'
    | 'treatment-decreasing-margins-wood'
    | 'treatment-wood'
    | 'treatment-loss-leader'
    | 'treatment-pleasant-decreasing'
    | 'treatment-pleasant-pricing'
    | 'treatment-wide'
  'remove-payment-selection': DEFAULT_VARIANTS
  'payment-order-bnpl': DEFAULT_VARIANTS
  'size-22x44': DEFAULT_VARIANTS
  'size-4x4': DEFAULT_VARIANTS
  'smart-crop': DEFAULT_VARIANTS
  'sms-consent': DEFAULT_VARIANTS
  'straight-to-photos':
    | 'off'
    | 'control'
    | 'treatment-with-skip'
    | 'treatment-without-skip'
  'wall-designer': DEFAULT_VARIANTS
  'xl-sizes': DEFAULT_VARIANTS
  'mixed-materials': DEFAULT_VARIANTS
  'split-homepage-new-copy': DEFAULT_VARIANTS
  'canvas-16x20': DEFAULT_VARIANTS
  'quality-guarantee-banner': DEFAULT_VARIANTS
  'capture-phone-number': DEFAULT_VARIANTS
  'amplitude-session-replay': DEFAULT_VARIANTS
  'mixtiles-plus': DEFAULT_VARIANTS
  referral: DEFAULT_VARIANTS
  'bounce-rollout-2': DEFAULT_VARIANTS
  'book-cover-fonts': DEFAULT_VARIANTS
  'hubbox-rollout': DEFAULT_VARIANTS
  'photo-books-fte': DEFAULT_VARIANTS
  'photobooks-core-integration-2': DEFAULT_VARIANTS
  'long-delivery-badge': DEFAULT_VARIANTS
  '8-8_best_offer_badge': DEFAULT_VARIANTS
}

export type Feature = keyof FeatureVariants

export type FeatureToVariant = {
  [F in Feature]: FeatureVariants[F]
}

// The variant that will be used if we failed getting variants from the server
export const defaultFeatureVariants: FeatureToVariant = {
  '3d-tiles-improved': 'off',
  blog: 'off',
  'books-new-upload-ux': 'off',
  canvas: 'off',
  christmas: 'off',
  collage: 'off',
  'collage-hebrew-text': 'off',
  'dpi-threshold-parametric': 'off',
  'gallery-walls': 'off',
  'holiday-badge': 'off',
  'homepage-new-header-2': 'off',
  'homepage-new-seen-on': 'off',
  'homepage-new-title': 'off',
  'homepage-new-products': 'off',
  halloween: 'off',
  jumble: 'off',
  'material-info-icon-expand': 'off',
  'new-cart-icon': 'off',
  'art-collection-dec-2024': 'off',
  'art-in-plus': 'off',
  'photo-books': 'off',
  'photo-books-medium-size': 'off',
  'photo-book-matting': 'off',
  'photo-books-photos-limit': 'off',
  pricing_us_simple_prices_jul_24: 'off',
  'remove-payment-selection': 'off',
  'payment-order-bnpl': 'off',
  'size-22x44': 'off',
  'size-4x4': 'off',
  'smart-crop': 'off',
  'sms-consent': 'off',
  'straight-to-photos': 'off',
  'wall-designer': 'off',
  'xl-sizes': 'off',
  'mixed-materials': 'off',
  'split-homepage-new-copy': 'off',
  'canvas-16x20': 'off',
  'quality-guarantee-banner': 'off',
  'capture-phone-number': 'off',
  'amplitude-session-replay': 'off',
  'mixtiles-plus': 'off',
  referral: 'off',
  'bounce-rollout-2': 'off',
  'book-cover-fonts': 'off',
  'hubbox-rollout': 'off',
  'photo-books-fte': 'off',
  'photobooks-core-integration-2': 'off',
  'long-delivery-badge': 'off',
  '8-8_best_offer_badge': 'off',
}
