import React from 'react'

function useOnMount(effectFn) {
  React.useEffect(effectFn, [])
}

export function useOnUnmount(effectFn) {
  React.useEffect(() => {
    return effectFn
  }, [])
}

export default useOnMount
